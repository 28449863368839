import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import wallet from "../assets/icons/wallet.svg";
import { toast } from "react-hot-toast";

function Signin(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  return (
    <div className="flex flex-col items-center mt-8 space-y-16">
      <div className="flex flex-col items-center space-y-2">
        <p className="text-xl font-bold">Sign In</p>
        <p className="text-sm">Connect your Wallet to Sign In</p>
      </div>
      <div className="">
        {props.appState.account === "" ? (
          <button
            className="w-64 text-white capitalize border-0 rounded-full bg-green btn"
            onClick={() => props.setUpWeb3()}
          >
            Connect Wallet
          </button>
        ) : (
          <p>
            {
              <div className="flex flex-col items-center space-y-4">
                <div className="flex flex-row space-x-2">
                  <img src={wallet} alt="wallet"></img>
                  <p className="font-bold text-md">
                    {props.appState.account.slice(0, 8) +
                      "..." +
                      props.appState.account.slice(-8)}
                  </p>
                </div>
                <input
                  className="items-center p-3 text-center text-black bg-white border-2 border-black rounded-full w-72"
                  placeholder="Enter your email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <button
                  className="text-white capitalize border-0 rounded-full bg-green w-72 btn"
                  onClick={() => props.walletDisconnect()}
                >
                  Disconnect Wallet
                </button>
                <button
                  className="text-white capitalize border-0 rounded-full bg-green w-72 btn"
                  onClick={() => {
                    if (email === "") {
                      toast.error("Please enter your email");
                    } else {
                      props.signin(email);
                    }
                  }}
                >
                  Login with Connected Wallet
                </button>
              </div>
            }
          </p>
        )}
      </div>
      <div className="flex flex-col ">
        <p className="mt-12 text-sm italic text-center w-80">
          “Blockchain can provide an immutable and transparent ledger for
          tracking waste management transactions, making it easier to ensure
          that waste is being disposed of properly”
        </p>
      </div>
    </div>
  );
}

export default Signin;
