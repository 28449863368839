import React, { useState } from "react";

function AggregatorDropdown(props) {
  const [selectedOption, setSelectedOption] = useState("");

  return (
    <div className="relative">
      <select
        value={selectedOption}
        onChange={(e) => {
          setSelectedOption(e.target.value);
          const value = e.target.value;
          console.log("Sending Value from Component >>> ",value);
          props.functionToCall(value);
        }}
        className="py-2 pl-6 text-white rounded-full appearance-none bg-custom-two w-60 focus:outline-none"
      >
        <option value="" disabled hidden>
          {props.label}
        </option>
        {props.optionsArray.length === 0 ? (
          <option value="" disabled>
            No Collectors
          </option>
        ) : (
          props.optionsArray.map((item) => (
            <option key={item.id} value={item.id} className="w-48">
              {item.id} - {item.name.substring(0, 12) + "..."}
            </option>
          ))
        )}
      </select>
    </div>
  );
}

export default AggregatorDropdown;
