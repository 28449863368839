import React from "react";
import { useNavigate } from "react-router-dom";

import iot from "../assets/images/iot.jpg";
import recycle from "../assets/images/recycle.jpg";
import blockchain from "../assets/images/blockchain.jpg";

function Homepages(props) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col mt-8 space-y-16">
      <div className="flex flex-col items-center space-y-3">
        <p className="text-5xl font-bold ">Building a Sustainable Future</p>
        <p className="w-[700px] text-center">
          Our innovative platform leverages blockchain and IoT to facilitate
          sustainable waste management practices
        </p>
      </div>
      <div className="flex flex-row justify-center space-x-28">
        <div className="flex flex-col items-center space-y-2">
          <img
            src={iot}
            alt=""
            className="object-cover h-44 w-72 rounded-3xl"
          />
          <p>IoT</p>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img
            src={recycle}
            alt=""
            className="object-cover h-44 w-72 rounded-3xl"
          />
          <p>Recycle</p>
        </div>
        <div className="flex flex-col items-center space-y-2">
          <img
            src={blockchain}
            alt=""
            className="object-cover h-44 w-72 rounded-3xl"
          />
          <p>Blockchain</p>
        </div>
      </div>
      <div className="flex flex-col items-center space-y-4">
        <p className="text-xl text-center">Experience our Proof of Concept</p>
        <div className="flex flex-row items-center space-x-16">
          <button className="w-32 text-white capitalize border-0 rounded-full bg-green btn"
            onClick={() => navigate("/signin")}
          >
            Signin
          </button>
          <button className="w-32 text-white capitalize border-0 rounded-full bg-green btn"
            onClick={() => navigate("/signup")}
          >
            Signup
          </button>
        </div>
      </div>
    </div>
  );
}

export default Homepages;
