import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import wallet from "../assets/icons/wallet.svg";
import { toast } from "react-hot-toast";

function Signup(props) {
  const navigate = useNavigate();

  // Inside your component
  const [selectedOption, setSelectedOption] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex flex-col items-center mt-8 space-y-16">
      <div className="flex flex-col items-center space-y-2">
        <p className="text-xl font-bold">Sign Up</p>
        <p className="text-sm">Connect your Wallet to Create Account</p>
      </div>
      <div className="">
        {props.appState.account === "" ? (
          <button
            className="w-64 text-white capitalize bg-green-600 border-0 rounded-full btn"
            onClick={() => props.setUpWeb3()}
          >
            Connect Wallet
          </button>
        ) : (
          <p>
            {
              <div className="flex flex-col items-center space-y-4">
                <div className="flex flex-row items-center space-x-2">
                  <img src={wallet} alt="wallet"></img>
                  <p className="font-bold text-md">
                    {props.appState.account.slice(0, 8) +
                      "..." +
                      props.appState.account.slice(-8)}
                  </p>
                </div>
                <div className="flex flex-col pb-4 space-y-2">
                  <button
                    className="text-white capitalize bg-green-600 border-0 rounded-full w-72 btn "
                    onClick={() => props.walletDisconnect()}
                  >
                    Disconnect Wallet
                  </button>
                </div>

                <input
                  className="items-center p-3 text-center text-black bg-white border-2 border-black rounded-full w-72"
                  placeholder="Enter your name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
                <input
                  className="items-center p-3 text-center text-black bg-white border-2 border-black rounded-full w-72"
                  placeholder="Enter your email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                {/* Select Role Button Here*/}
                <div className="relative">
                  <select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="items-center p-3 text-center text-black capitalize bg-white border-2 border-black rounded-full appearance-none w-72"
                  >
                    <option value="" disabled hidden>
                      Select your role
                    </option>
                    <option value="donor">Donor</option>
                    <option value="aggregator">Aggregator</option>
                    <option value="collector">Collector</option>
                    <option value="recycler">Recycler</option>
                    {/* <option value="reFacility">Recycling Facility</option> */}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 text-black pointer-events-none"></div>
                </div>
                <button
                  className="text-white capitalize bg-green-600 border-0 rounded-full w-72 btn"
                  onClick={() => {
                    if (name === "") toast.error("Please enter your name");
                    else if (email === "") toast.error("Please enter your email");
                    else if (selectedOption === "")
                      toast.error("Please select your role");
                    else props.signup(name, selectedOption, email);
                  }}
                >
                  Create Account
                </button>
              </div>
            }
          </p>
        )}
      </div>
      <div className="flex flex-col ">
        <p className="mt-4 text-sm italic text-center w-80">
          “Blockchain can provide an immutable and transparent ledger for
          tracking waste management transactions, making it easier to ensure
          that waste is being disposed of properly”
        </p>
      </div>
    </div>
  );
}

export default Signup;
