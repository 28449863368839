import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../assets/images/logo.png";
import wallet from "../assets/icons/wallet.svg";

function Navbar(props) {
  const navigate = useNavigate();
  const [theme, setTheme] = useState("green");
  useEffect(() => {
    console.log("Navbar", window.location.pathname);
    if (props.appState.role === "buyer") {
      setTheme("custom-one");
    } else if (props.appState.role === "aggregator-one") {
      setTheme("custom-two");
    } else if (props.appState.role === "aggregator-two") {
      setTheme("custom-three");
    } else if (props.appState.role === "recycler-one") {
      setTheme("custom-four");
    } else if (props.appState.role === "recycler-two") {
      setTheme("custom-five");
    }
  }, []);

  return (
    <>
      <div className="grid grid-cols-3 px-8 py-8">
        <div>
          <img src={logo} alt="logo" className="h-16 w-36" />
        </div>
        <div className="flex flex-row items-center justify-between">
          {window.location.pathname === "/dashboard" ? (
            <div className="flex flex-col items-center justify-center w-full">
              <p className="text-xl font-bold">{props.appState.userProfile.name}</p>
              <p className="text-sm font-light capitalize">{props.appState.userProfile.role}</p>
            </div>
          ) : (
            <>
              <div>
                <p
                  className="hover:underline hover:underline-offset-4 hover:cursor-pointer"
                  onClick={() => navigate("/")}
                >
                  Home
                </p>
              </div>
              <div>
                <p className="hover:underline hover:underline-offset-4 hover:cursor-pointer">
                  Whitepaper
                </p>
              </div>
              <div>
                <p className="hover:underline hover:underline-offset-4 hover:cursor-pointer">
                  About
                </p>
              </div>
              <div>
                <p className="hover:underline hover:underline-offset-4 hover:cursor-pointer">
                  Contact
                </p>
              </div>
            </>
          )}
        </div>
        {props.appState.account !== "" &&
        window.location.pathname === "/dashboard" ? (
          <div className="flex flex-row items-center justify-end space-x-4">
            <div className="flex flex-row items-center space-x-2">
              <img src={wallet} alt="wallet"></img>
              <p className="text-sm font-bold">
                {props.appState.account.slice(0, 8) +
                  "..." +
                  props.appState.account.slice(-8)}
              </p>
            </div>
            <button
              className={`text-white capitalize border-0 rounded-full bg-${theme} w-28 btn btn-md `}
              onClick={() => props.walletLogout()}
            >
              Logout
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Navbar;
