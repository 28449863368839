import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import Barcode from "react-barcode";

import barcode from "../../assets/images/barcode.png";
import company from "../../assets/icons/company.svg";
import weight from "../../assets/icons/weight.svg";
import aggregator from "../../assets/icons/aggregator.svg";
import location from "../../assets/icons/location.svg";
import timestamp from "../../assets/icons/timestamp.svg";
import status from "../../assets/icons/status.svg";
import collector from "../../assets/icons/collector.svg";
import recycler from "../../assets/icons/recycler.svg";
import images from "../../assets/icons/images.svg";
import AggregatorDropdown from "../../components/AggregatorDropdown";

function AggDashboard(props) {
  const [theme, setTheme] = useState("light");
  const [shipmentHistory, setShipmentHistory] = useState([]);
  const [pickupShipments, setPickupShipments] = useState([]);
  const [trackShipmentId, setTrackShipmentId] = useState("");
  //   const [selectedRecycler, setSelectedRecycler] = useState("");
  //   const [selectedCollector, setSelectedCollector] = useState("");
  const [trackShipment, setTrackShipment] = useState({
    id: "",
    status: "",
    donor: {
      name: "",
      qty: "",
      timestamp: "",
      wasteType: "",
      location: "",
      aggregatorId: "",
      aggregatorName: "",
      id: "",
    },
  });
  const [modalShipment, setModalShipment] = useState({
    id: "",
    status: "",
    donor: {
      name: "",
      qty: "",
      timestamp: "",
      wasteType: "",
      location: "",
      aggregatorId: "",
      aggregatorName: "",
      id: "",
    },
  });

  let selectedRecycler = "";
  let selectedCollector = "";

  const handleCollectorChange = (value) => {
    console.log(
      "[+] Setting Collector >>>> ",
      "now",
      value,
      "previous",
      selectedCollector
    );
    selectedCollector = value;
  };

  const handleRecyclerChange = (value) => {
    console.log(
      "[+] Setting Recycler >>>> ",
      "now",
      value,
      "previous",
      selectedRecycler
    );
    selectedRecycler = value;
  };

  const processPickupShipments = () => {
    let tempPickups = [];
    setPickupShipments([]);
    if (props.appState.shipments.length > 0) {
      console.log("Processing Pickup Shipments:", props.appState.shipments);
      props.appState.shipments.forEach((shipment) => {
        if (shipment.status === "Request to Aggregator") {
          let element = (
            <div className="flex flex-col p-4 px-8 bg-custom-two/30 rounded-3xl">
              <div className="text-sm text-white rounded-md w-fit bg-custom-two">
                <p className="p-2">#{shipment.id}</p>
              </div>
              <div className="grid grid-cols-2 grid-rows-3 mt-4 mb-4 gap-x-24 gap-y-4">
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={company} alt="company" className="w-6 h-6" />
                    <p className="text-sm font-bold">Company</p>
                  </div>
                  <p className="self-center text-sm font-normal">
                    {shipment.donor.name}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={weight} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Weight</p>
                  </div>
                  <p className="self-center text-sm">
                    {shipment.donor.qty} Kgs.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={aggregator} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Aggregator</p>
                  </div>
                  <div className="flex flex-col justify-center">
                    <p className="text-sm font-normal">
                      {shipment.donor.aggregatorName}
                    </p>
                    <p className="text-xs font-normal">
                      ID - {shipment.donor.aggregatorId}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-start space-x-4">
                    <img src={location} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Location</p>
                  </div>
                  <p className="self-center w-40 text-sm ">
                    {shipment.donor.location}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={timestamp} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Timestamp</p>
                  </div>
                  <p className="self-center text-sm font-normal ">
                    {shipment.donor.timestamp}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={status} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Status</p>
                  </div>
                  <p className="self-center text-sm">{shipment.status}</p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between space-x-4">
                <div className="flex flex-row space-x-4">
                  {/* comp here */}
                  <AggregatorDropdown
                    label="Assign Collector"
                    optionsArray={props.appState.collectors}
                    functionToCall={handleCollectorChange}
                  />
                  <AggregatorDropdown
                    label="Assign Recycler"
                    optionsArray={props.appState.recyclers}
                    functionToCall={handleRecyclerChange}
                  />
                </div>
                <button
                  className="px-10 py-1 text-white capitalize border-0 rounded-full btn bg-custom-two"
                  onClick={() => {
                    console.log(
                      "Accepting Pickup",
                      shipment.id,
                      selectedCollector,
                      selectedRecycler
                    );
                    if (selectedCollector === "" || selectedRecycler === "") {
                      toast.error("Please select both collector and recycler.");
                    } else {
                      props.pickupShipment(
                        selectedCollector,
                        selectedRecycler,
                        shipment.id
                      );
                    }
                  }}
                >
                  Accept Pickup
                </button>
              </div>
            </div>
          );
          tempPickups.push(element);
        }
      });
      setPickupShipments(tempPickups);
    } else {
      console.log("No Shipments Found.");
    }
  };

  const processHistoryShipments = () => {
    let tempShipmentHistory = [];
    if (props.appState.shipments.length > 0) {
      console.log("Processing Shipment History:", props.appState.shipments);

      // Sort shipments based on timestamp
      const sortedShipments = props.appState.shipments.sort((a, b) => {
        const timestampA = new Date(
          a.donor.timestamp.replace(",", "")
        ).getTime();
        const timestampB = new Date(
          b.donor.timestamp.replace(",", "")
        ).getTime();
        return timestampB - timestampA;
      });

      sortedShipments.forEach((shipment) => {
        if (shipment.aggregator) {
          let element = (
            <div className="flex flex-row items-center space-x-24 ">
              <p className="w-40">#{shipment.id}</p>
              <p className="w-48">{shipment.aggregator.timestamp}</p>
              <label
                htmlFor="my-modal"
                className={`"text-white capitalize bg-${theme} border-0 rounded-full btn btn-sm w-24 text-sm "`}
                onClick={() => {
                  console.log("View Shipment:", shipment.id);
                  setModalShipment(shipment);
                }}
              >
                View
              </label>
            </div>
          );
          tempShipmentHistory.push(element);
          setShipmentHistory(tempShipmentHistory);
        }
      });
    } else {
      console.log("No Shipments Found.");
    }
  };

  useEffect(() => {
    processHistoryShipments();
    processPickupShipments();
  }, [props.appState]);

  useEffect(() => {
    if (props.appState.userProfile.role === "donor") {
      setTheme("custom-one");
    } else if (props.appState.userProfile.role === "aggregator") {
      setTheme("custom-two");
    } else if (props.appState.userProfile.role === "aggregator-two") {
      setTheme("custom-three");
    } else if (props.appState.userProfile.role === "recycler-one") {
      setTheme("custom-four");
    } else if (props.appState.userProfile.role === "recycler-two") {
      setTheme("custom-five");
    }
  }, []);

  return (
    <>
      {/* Modal */}
      {/* ============================================== */}
      <input type="checkbox" id="my-modal" className="modal-toggle" />
      <div className="overflow-auto bg-white modal">
        <div className="flex flex-col items-center w-full mx-16  pt-[900px] pb-20">
          <div className="w-fit rounded-2xl">
            <div className="flex flex-col w-fit">
              {/* Container - 1 */}
              <div className="flex flex-col p-4 px-8 bg-custom-one/30 rounded-3xl">
                <div className="text-sm text-white rounded-md w-fit bg-custom-one">
                  <p className="p-2">#{modalShipment.id}</p>
                </div>
                <div className="grid grid-cols-2 grid-rows-3 mt-4 mb-4 gap-x-24 gap-y-4">
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={company} alt="company" className="w-6 h-6" />
                      <p className="text-sm font-bold">Company</p>
                    </div>
                    <p className="self-center text-sm font-normal">
                      {modalShipment.donor.name}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={weight} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Weight</p>
                    </div>
                    <p className="self-center text-sm">
                      {modalShipment.donor.qty} Kgs.
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={aggregator} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Aggregator</p>
                    </div>
                    <div className="flex flex-col justify-center">
                      <p className="text-sm font-normal">
                        {modalShipment.donor.aggregatorName}
                      </p>
                      <p className="text-xs font-normal">
                        ID - {modalShipment.donor.aggregatorId}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-start space-x-4">
                      <img src={location} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Location</p>
                    </div>
                    <p className="self-center w-40 text-sm ">
                      {modalShipment.donor.location}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={timestamp} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Timestamp</p>
                    </div>
                    <p className="self-center text-sm font-normal ">
                      {modalShipment.donor.timestamp}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={status} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Status</p>
                    </div>
                    <p className="self-center text-sm">
                      {modalShipment.status}
                    </p>
                  </div>
                </div>
              </div>
              {/* Container - 2 */}
              {modalShipment.aggregator ? (
                <div className="flex flex-col p-4 px-8 bg-custom-two/30 rounded-3xl">
                  <div className="grid grid-cols-2 grid-rows-2 mt-4 mb-4 gap-x-24 gap-y-4">
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={collector} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Collector</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">
                          {modalShipment.aggregator.collectorName}
                        </p>
                        <p className="text-xs font-normal">
                          ID - {modalShipment.aggregator.collectorId}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={recycler} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Recycler</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">
                          {modalShipment.aggregator.recyclerName}
                        </p>
                        <p className="text-xs font-normal">
                          ID - {modalShipment.aggregator.recyclerId}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Assigned Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.aggregator.timestamp}
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Assigned Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.aggregator.timestamp}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Container - 3 */}
              {modalShipment.collector ? (
                <div className="flex flex-col p-4 px-8 py-6 bg-custom-three/30 rounded-3xl">
                  <div className="text-sm text-black rounded-md w-fit ">
                    {/* <img src={barcode} alt="barcode" /> */}
                    <Barcode
                      value={modalShipment.id}
                      height={40}
                      displayValue={false}
                      margin={0}
                      background="#C4DCB8"
                    />
                  </div>
                  <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={collector} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Collector</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">
                          {modalShipment.collector.collectorName}
                        </p>
                        <p className="text-xs font-normal">
                          ID - {modalShipment.collector.collectorId}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={weight} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Weight</p>
                      </div>
                      <p className="self-center text-sm">
                        {modalShipment.collector.weight} Kgs.
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Pickup Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.collector.timestamp}
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={images} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Images</p>
                      </div>
                      <p className="self-center text-sm underline hover:cursor-pointer">
                        View
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={status} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Status</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.collector.status}
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img
                          src={location}
                          alt="location"
                          className="w-6 h-6"
                        />
                        <p className="text-sm font-bold">Location</p>
                      </div>
                      <p className="self-center text-sm">
                        {modalShipment.collector.location}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Container - 4 */}
              {modalShipment.recycler ? (
                <div className="flex flex-col p-4 px-8 py-6 bg-custom-four/30 rounded-3xl">
                  <div className="text-sm text-black rounded-md w-fit ">
                    <Barcode
                      value={modalShipment.id}
                      height={40}
                      displayValue={false}
                      margin={0}
                      background="#C4DCB8"
                    />
                  </div>
                  <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={recycler} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Recycler</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">
                          {modalShipment.recycler.recyclerName}
                        </p>
                        <p className="text-xs font-normal">
                          ID -{modalShipment.recycler.recyclerId}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={weight} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Gross Weight</p>
                      </div>
                      <p className="self-center text-sm">
                        {modalShipment.recycler.weight}&nbsp; Kgs.
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Recycle Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.recycler.timestamp}
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={images} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Images</p>
                      </div>
                      <p className="self-center text-sm underline hover:cursor-pointer">
                        View
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={status} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Status</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        Recycled
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img
                          src={location}
                          alt="location"
                          className="w-6 h-6"
                        />
                        <p className="text-sm font-bold">Location</p>
                      </div>
                      <p className="self-center text-sm">
                        {modalShipment.recycler.location}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col items-center w-full modal-action">
              <label
                htmlFor="my-modal"
                className="w-40 capitalize border-0 rounded-full btn bg-green"
              >
                Close
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* ============================================== */}
      {/* Modal End */}
      <div className="flex flex-col my-8 mb-8">
        {/* Container - 1 */}
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col mx-48">
            <p className="text-lg font-bold">Available Pickups</p>
            <p className="text-sm">
              Select the desired pickup and assign a collector and a recycler
            </p>
            {/* <button
              className="btn"
              onClick={() => {
                // console.log(props.appState);
                processHistoryShipments();
              }}
            >
              State
            </button> */}
          </div>
          <div className="flex flex-col space-y-4 mx-28">
            {pickupShipments.length > 0 ? (
              pickupShipments
            ) : (
              <p className="pt-4 ml-20 text-xl font-bold text-black/50">
                No Pickups Available
              </p>
            )}
          </div>
          <div className="flex flex-col space-y-4"></div>
        </div>
        <div className="mx-48 divider"></div>
        {/* Container - 2 */}
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col mx-48">
            <p className="text-lg font-bold">Track Shipment Status</p>

            <p className="text-sm">Provide the Shipment ID to Track</p>
          </div>
          <div className="flex flex-row mx-48 space-x-4">
            <input
              className="p-2 pl-5 border-2 border-black rounded-full w-60"
              placeholder="Shipment ID"
              type="text"
              value={trackShipmentId}
              onChange={(e) => setTrackShipmentId(e.target.value)}
            ></input>
            <button
              className={`"text-white capitalize bg-${theme} border-0 rounded-full w-72 btn text-sm py-0"`}
              onClick={async () => {
                console.log("SHIPMENT ID", trackShipmentId);
                if (trackShipmentId !== undefined && trackShipmentId !== "") {
                  const shipment = props.appState.shipments.find(
                    (item) => item.id.toString() === trackShipmentId
                  );
                  if (shipment === undefined) {
                    console.log(shipment);
                    toast.error("Shipment not found");
                  } else {
                    console.log("Shipment set for track", shipment);
                    setTrackShipment(shipment);
                  }
                } else {
                  toast.error("Please enter a valid shipment id");
                }
              }}
            >
              Track Shipment
            </button>
          </div>
          {/* Start of Shipment Card for Tracking */}
          {trackShipment.id === "" ? null : (
            <div className="flex flex-col items-center w-full pt-4">
              <div className=" w-fit rounded-2xl">
                <div className="flex flex-col w-fit">
                  {/* Container - 1 */}
                  <div className="flex flex-col p-4 px-8 bg-custom-one/30 rounded-3xl">
                    <div className="text-sm text-white rounded-md w-fit bg-custom-one">
                      <p className="p-2">#{trackShipment.id}</p>
                    </div>
                    <div className="grid grid-cols-2 grid-rows-3 mt-4 mb-4 gap-x-24 gap-y-4">
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img
                            src={company}
                            alt="company"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Company</p>
                        </div>
                        <p className="self-center text-sm font-normal">
                          {trackShipment.donor.name}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img src={weight} alt="weight" className="w-6 h-6" />
                          <p className="text-sm font-bold">Weight</p>
                        </div>
                        <p className="self-center text-sm">
                          {trackShipment.donor.qty} Kgs.
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img
                            src={aggregator}
                            alt="weight"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Aggregator</p>
                        </div>
                        <div className="flex flex-col justify-center">
                          <p className="text-sm font-normal">
                            {trackShipment.donor.aggregatorName}
                          </p>
                          <p className="text-xs font-normal">
                            ID - {trackShipment.donor.aggregatorId}
                          </p>
                        </div>
                      </div>
                      <div className="grid items-center grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-start space-x-4">
                          <img
                            src={location}
                            alt="weight"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Location</p>
                        </div>
                        <p className="self-center w-40 text-sm ">
                          {trackShipment.donor.location}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img
                            src={timestamp}
                            alt="weight"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Timestamp</p>
                        </div>
                        <p className="self-center text-sm font-normal ">
                          {trackShipment.donor.timestamp}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img src={status} alt="weight" className="w-6 h-6" />
                          <p className="text-sm font-bold">Status</p>
                        </div>
                        <p className="self-center text-sm">
                          {trackShipment.status}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Container - 2 */}
                  {trackShipment.aggregator ? (
                    <div className="flex flex-col p-4 px-8 bg-custom-two/30 rounded-3xl">
                      <div className="grid grid-cols-2 grid-rows-2 mt-4 mb-4 gap-x-24 gap-y-4">
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={collector}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Collector</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">
                              {trackShipment.aggregator.collectorName}
                            </p>
                            <p className="text-xs font-normal">
                              ID -&nbsp;{trackShipment.aggregator.collectorId}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={recycler}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Recycler</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">
                              {trackShipment.aggregator.recyclerName}
                            </p>
                            <p className="text-xs font-normal">
                              ID -&nbsp;{trackShipment.aggregator.recyclerId}
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Assigned Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            {trackShipment.aggregator.timestamp}
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Assigned Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            {trackShipment.aggregator.timestamp}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* Container - 3 */}
                  {trackShipment.collector ? (
                    <div className="flex flex-col p-4 px-8 py-6 bg-custom-three/30 rounded-3xl">
                      <div className="text-sm text-black rounded-md w-fit ">
                        <Barcode
                          value={trackShipment.id}
                          height={40}
                          displayValue={false}
                          margin={0}
                          background="#C4DCB8"
                        />
                      </div>
                      <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={collector}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Collector</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">
                              {trackShipment.collector.collectorName}
                            </p>
                            <p className="text-xs font-normal">
                              ID -&nbsp;{trackShipment.collector.collectorId}
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={weight}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Weight</p>
                          </div>
                          <p className="self-center text-sm">
                            {trackShipment.collector.weight}
                            Kgs.
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Pickup Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            {trackShipment.collector.timestamp}
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={images}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Images</p>
                          </div>
                          <p className="self-center text-sm underline hover:cursor-pointer">
                            View
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={status}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Status</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            {trackShipment.collector.status}
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={location}
                              alt="location"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Location</p>
                          </div>
                          <p className="self-center w-48 text-sm">
                            {trackShipment.collector.location}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* Container - 4 */}
                  {trackShipment.recycler ? (
                    <div className="flex flex-col p-4 px-8 py-6 bg-custom-four/30 rounded-3xl">
                      <div className="text-sm text-black rounded-md w-fit ">
                        <Barcode
                          value={trackShipment.id}
                          height={40}
                          displayValue={false}
                          margin={0}
                          background="#C4DCB8"
                        />
                      </div>
                      <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={recycler}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Recycler</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">
                              {trackShipment.recycler.recyclerName}
                            </p>
                            <p className="text-xs font-normal">
                              ID - &nbsp;
                              {trackShipment.recycler.recyclerId}
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={weight}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Gross Weight</p>
                          </div>
                          <p className="self-center text-sm">
                            {trackShipment.recycler.weight}
                            Kgs.
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Recycle Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            {trackShipment.recycler.timestamp}
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={images}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Images</p>
                          </div>
                          <p className="self-center text-sm underline hover:cursor-pointer">
                            View
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={status}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Status</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            {trackShipment.recycler.status}
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={location}
                              alt="location"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Location</p>
                          </div>
                          <p className="self-center w-40 text-sm">
                            {trackShipment.recycler.location}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {/* End of Shipment Card for Tracking */}
        </div>
        <div className="mx-48 divider"></div>
        {/* Container - 3 */}
        <div className="flex flex-col mx-48 mb-16 space-y-4">
          <div className="flex flex-col">
            <p className="text-lg font-bold">Shipment History</p>
            <p className="text-sm">History of previous shipments</p>
          </div>
          {shipmentHistory.length === 0 ? (
            <div>
              <p className="text-xl font-bold text-black/50">No Shipments</p>
            </div>
          ) : (
            <div className="flex flex-col space-y-2">{shipmentHistory}</div>
          )}
        </div>
      </div>
    </>
  );
}

export default AggDashboard;
