import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Routes, Route, useNavigate } from "react-router-dom";
import Web3 from "web3";
import axios, { all } from "axios";

import abi from "./smart-contract/abi.json";

import "./App.css";
import Loading from "./components/Loading";
import Navbar from "./components/Navbar";

import Homepage from "./pages/Homepage";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    loading: false,
    message: "",
  });
  const [appState, setAppState] = useState({
    web3: null,
    account: "",
    loggedIn: false,
    role: "",
    userProfile: {
      name: "",
      id: "",
      role: "",
    },
    contractAddress: "0x16E4fbD9C72de7Ad3e8c19063453945Af0D24B8A",
    backendServer: "https://tracebackend.goenvitechnologies.com",
    shipments: [],
    aggregators: [],
    recyclers: [],
    collectors: [],
    recycles: [],
  });

  const generateNumberFromAddress = (walletAddress) => {
    const numbersFromAddress = walletAddress.replace(/\D/g, ""); // Extract only the numbers from the wallet address
    console.log(
      "<< Numbers from address: ",
      numbersFromAddress,
      " >>",
      typeof numbersFromAddress,
      numbersFromAddress.length
    );

    const additionalNumbersNeeded = 12 - numbersFromAddress.length; // Calculate how many additional random numbers are needed
    console.log(
      "<< Additional numbers needed: ",
      additionalNumbersNeeded,
      " >>"
    );

    let generatedNumber = numbersFromAddress; // Start with the numbers from the wallet address

    for (let i = 0; i < additionalNumbersNeeded; i++) {
      const randomDigit = Math.floor(Math.random() * 10); // Generate a random single-digit number (0-9)
      generatedNumber += randomDigit; // Append the random digit to the generated number
    }

    return generatedNumber;
  };

  const setUpWeb3 = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        setLoading({
          loading: true,
          message: "Connecting to Metamask...",
        });
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(window.ethereum);
        setAppState((prevState) => {
          return { ...prevState, web3: web3 };
        });
        console.log("<< Web3 Object Received  >>");

        window.ethereum
          .request({ method: "net_version" })
          .then(async (chainId) => {
            if (chainId !== "80001") {
              try {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0x13881" }],
                });
                console.log("Polygon Mumbai Chain found.");
              } catch (switchError) {
                console.log("Error connecting to Polygon Mumbai Chain (1)");
              }
            }
          });

        const accounts = await web3.eth.getAccounts();
        console.log("<< Account Received  >>", accounts[0]);

        setAppState((prevState) => {
          return {
            ...prevState,
            account: accounts[0],
          };
        });
        setLoading({
          loading: false,
          message: "Connecting to Metamask...",
        });
      } catch (error) {
        console.error(error);
        console.log("Error getting web3 object. Install Metamask.");
        setLoading({
          loading: false,
          message: "Connecting to Metamask...",
        });
      }
    } else {
      console.log("Please install MetaMask to connect your wallet.");
    }
  };

  const walletDisconnect = async () => {
    console.log("<< Wallet Disconnect Called  >>");
    setAppState((prevState) => {
      return {
        ...prevState,
        loggedIn: false,
        account: "",
      };
    });
  };

  const walletLogout = async () => {
    console.log("<< Wallet Logout Called  >>");
    setAppState((prevState) => {
      return {
        ...prevState,
        loggedIn: false,
        account: "",
      };
    });
    navigate("/");
  };

  const signup = async (name, role, email) => {
    console.log("<< Signup Called  >>", name, role);

    // generate random 3 digit number
    let id = Math.floor(100 + Math.random() * 900);
    id = id.toString() + appState.account.slice(-3).toUpperCase();

    console.log("[+] ID Generated: ", id);

    const contract = new appState.web3.eth.Contract(
      abi,
      appState.contractAddress
    );

    axios
      .post(`${appState.backendServer}/signup`, {
        walletAddress: appState.account,
        name: name,
        role: role,
        email: email,
        id: id,
      })
      .then(async (response) => {
        console.log("[+] Signup Successful", response.data);
        const contractResponse = await contract.methods
          .addCompany(appState.account, role, name, id.toString())
          .send({
            from: appState.account,
          })
          .then((response) => {
            console.log("[+] Company Added to Smart Contract", response);
            toast.success("Signup Successful. Please Sign In.");
            navigate("/signin");
          })
          .catch((error) => {
            console.log("[-] Company Addition Failed", error);
            toast.error(
              "Signup Failed. Please try again. Account already exists."
            );
          });
      })
      .catch((error) => {
        console.log("[-] Signup Failed", error);
        toast.error("Signup Failed in Smart Contract");
      });
  };

  const signin = async (email) => {
    console.log("<< Signin Called  >>");

    const contract = new appState.web3.eth.Contract(
      abi,
      appState.contractAddress
    );

    axios
      .post(`${appState.backendServer}/signin`, {
        walletAddress: appState.account,
        email: email,
      })
      .then((response) => {
        console.log("[+] Signin Successful", response.data.user);
        setAppState((prevState) => {
          return {
            ...prevState,
            loggedIn: true,
            userProfile: {
              name: response.data.user.name,
              id: response.data.user.id,
              role: response.data.user.role,
              email: response.data.user.email,
            },
          };
        });
        if (response.data.user.role === "donor") {
          allShipmentsDonor(response.data.user.id, "1");
          getAllAggregators();
        } else if (response.data.user.role === "aggregator") {
          allShipmentsAggregator(response.data.user.id, "1");
          getAllCollectors();
          getAllRecyclers();
        } else if (response.data.user.role === "collector") {
          allShipmentsCollector(response.data.user.id, "1");
        } else if (response.data.user.role === "recycler") {
          allShipmentsRecycler(response.data.user.id, "1");
          getAllRecycleFacilities();
          getAllRecycles();
        }
        toast.success("Signin Successful.");
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log("[-] Signin Failed", error);
        toast.error(
          "Signin Failed. Invalid Credentials/Account does not exist."
        );
      });
  };

  const sendRequestToAggregator = async (wasteType, qty, location, aggId) => {
    console.log("<< Send Request Called  >>", wasteType, qty, location);
    setLoading({
      loading: true,
      message: "Sending Request to Aggregator...",
    });
    let shipmentId = Math.floor(100000000000 + Math.random() * 900000000000);
    const aggName = appState.aggregators.find(
      (item) => item.id === aggId
    )?.name;

    const contract = new appState.web3.eth.Contract(
      abi,
      appState.contractAddress
    );

    // uint256 _shipmentId,
    //     uint256 _collectorId,
    //     uint256 _recyclerId,
    //     uint256 _aggregatorId,
    //     string memory _status,
    //     uint256 _timestampCreated,
    //     address _addressOfTheCreator

    let request = {
      id: shipmentId,
      status: "Request to Aggregator",
      donor: {
        id: appState.userProfile.id,
        name: appState.userProfile.name,
        wasteType: wasteType,
        qty: qty,
        location: location,
        aggregatorId: aggId,
        aggregatorName: aggName,
        timestamp: new Date()
          .toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
          .replace(",", " -"),
      },
    };
    console.log("[+] Request Object: ", request);

    await axios
      .post(`${appState.backendServer}/sendRequestToAggregator`, request)
      .then(async (response) => {
        console.log("[+] Request Sent Successful", response.data);
        const contractResponse = await contract.methods
          .addShipment(
            shipmentId,
            "",
            "",
            "",
            "Requested to Aggregator",
            new Date()
              .toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })
              .replace(",", " -"),
            appState.account
          )
          .send({
            from: appState.account,
          })
          .then((response) => {
            console.log("[+] Shipment Added to Smart Contract", response);
            toast.success("Request sent successfully.");
            setLoading({
              loading: false,
              message: "Sending Request to Aggregator...",
            });
            allShipmentsDonor(appState.userProfile.id, "0");
            return shipmentId;
          })
          .catch((error) => {
            console.log("[-] Shipment Addition Failed", error);
          });
      })
      .catch((error) => {
        console.log("[-] Request Sent Failed", error);
        toast.error("Request Sent Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Sending Request to Aggregator...",
        });
      });
    return shipmentId;
  };

  const allShipmentsDonor = async (donorId, mode) => {
    console.log("<< All Shipments Donor Called  >>", donorId);
    if (mode !== "0") {
      setLoading({
        loading: true,
        message: "Fetching Shipments...",
      });
    }
    await axios
      .get(`${appState.backendServer}/shipments/donor/${donorId}`)
      .then((response) => {
        console.log("[+] Shipments fetch Successful", response.data);
        setAppState((prevState) => {
          return {
            ...prevState,
            shipments: response.data.batches,
          };
        });
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      })
      .catch((error) => {
        console.log("[-] Donations Failed", error);
        toast.error("Donations Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      });
  };

  const getAllAggregators = () => {
    console.log("<< All Aggregators Called  >>");
    axios.get(`${appState.backendServer}/aggregators`).then((response) => {
      console.log("[+] Aggregators fetch Successful", response.data);
      setAppState((prevState) => {
        return {
          ...prevState,
          aggregators: response.data,
        };
      });
    });
  };

  const getAllCollectors = () => {
    console.log("<< All Collectors Called  >>");
    axios.get(`${appState.backendServer}/collectors`).then((response) => {
      console.log("[+] Collectors fetch Successful", response.data);
      setAppState((prevState) => {
        return {
          ...prevState,
          collectors: response.data,
        };
      });
    });
  };

  const getAllRecyclers = () => {
    console.log("<< All Recyclers Called  >>");
    axios.get(`${appState.backendServer}/recyclers`).then((response) => {
      console.log("[+] Recyclers fetch Successful", response.data);
      setAppState((prevState) => {
        return {
          ...prevState,
          recyclers: response.data,
        };
      });
    });
  };

  const getAllRecycleFacilities = () => {
    console.log("<< All Recycle Facilities Called  >>");
    axios
      .get(`${appState.backendServer}/recycleFacilities`)
      .then((response) => {
        console.log("[+] Recycle Facilities fetch Successful", response.data);
        setAppState((prevState) => {
          return {
            ...prevState,
            recycleFacilities: response.data,
          };
        });
      });
  };

  const allShipmentsAggregator = async (aggId, mode) => {
    console.log("<< All Shipments Aggregator Called  >>", aggId);
    if (mode !== "0") {
      setLoading({
        loading: true,
        message: "Fetching Shipments...",
      });
    }
    await axios
      .get(`${appState.backendServer}/shipments/agg/${aggId}`)
      .then((response) => {
        console.log("[+] Shipments fetch Successful", response.data);
        setAppState((prevState) => {
          return {
            ...prevState,
            shipments: response.data.batches,
          };
        });
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      })
      .catch((error) => {
        console.log("[-] Shipments Failed", error);
        toast.error("Shipments Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      });
  };

  const allShipmentsCollector = async (collId, mode) => {
    console.log("<< All Shipments Collector Called  >>", collId);
    if (mode !== "0") {
      setLoading({
        loading: true,
        message: "Fetching Shipments...",
      });
    }
    await axios
      .get(`${appState.backendServer}/shipments/coll/${collId}`)
      .then((response) => {
        console.log("[+] Shipments fetch Successful", response.data);
        setAppState((prevState) => {
          return {
            ...prevState,
            shipments: response.data.batches,
          };
        });
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      })
      .catch((error) => {
        console.log("[-] Shipments Failed", error);
        toast.error("Shipments Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      });
  };

  const allShipmentsRecycler = async (recId, mode) => {
    if (mode !== "0") {
      setLoading({
        loading: true,
        message: "Fetching Shipments...",
      });
    }
    await axios
      .get(`${appState.backendServer}/shipments/recycler/${recId}`)
      .then((response) => {
        console.log("[+] Shipments fetch Successful", response.data);
        setAppState((prevState) => {
          return {
            ...prevState,
            shipments: response.data.batches,
          };
        });
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      })
      .catch((error) => {
        console.log("[-] Shipments Failed", error);
        toast.error("Shipments Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Fetching Shipments...",
        });
      });
  };

  const pickupShipment = async (cId, rId, shipmentId) => {
    console.log("<< Pickup Shipment Called  >>", cId, rId, shipmentId);
    setLoading({
      loading: true,
      message: "Picking up Shipment...",
    });
    let request = {
      id: shipmentId,
      status: "Roles Assigned",
      aggregator: {
        aggregatorId: appState.userProfile.id,
        name: appState.userProfile.name,
        timestamp: new Date()
          .toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
          .replace(",", " -"),
        collectorId: cId,
        collectorName: appState.collectors.find((c) => c.id === cId).name,
        recyclerId: rId,
        recyclerName: appState.recyclers.find((r) => r.id === rId).name,
      },
    };

    console.log("[+] Request Object: ", request);

    const contract = new appState.web3.eth.Contract(
      abi,
      appState.contractAddress
    );

    await axios
      .post(`${appState.backendServer}/pickupShipment`, request)
      .then(async (response) => {
        console.log("[+] Pickup Successful", response.data);
        const contractResponse = await contract.methods
          .updateShipmentWithAggregatorId(shipmentId, appState.userProfile.id)
          .send({ from: appState.account })
          .then((response) => {
            console.log("[+] Contract Response: ", response);
            toast.success("Pickup successful.");
            setLoading({
              loading: false,
              message: "Picking up Shipment...",
            });
            allShipmentsAggregator(appState.userProfile.id, "0");
            return shipmentId;
          })
          .catch((error) => {
            console.log("[-] Contract Response: ", error);
            toast.error("Pickup Failed. Please try again.");
          });
      })
      .catch((error) => {
        console.log("[-] Pickup Failed", error);
        toast.error("Pickup Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Picking up Shipment...",
        });
      });
  };

  const pickupShipmentCollector = async (shipmentId, collectorData) => {
    console.log("<< Pickup Shipment Collector Called  >>", shipmentId);
    setLoading({
      loading: true,
      message: "Picking up Shipment...",
    });
    let request = {
      id: shipmentId,
      status: "Collected by Collector",
      collector: { ...collectorData },
    };

    console.log("[+] Request Object: ", request);

    const contract = new appState.web3.eth.Contract(
      abi,
      appState.contractAddress
    );

    await axios
      .post(`${appState.backendServer}/pickup-shipment-collector`, request)
      .then(async (response) => {
        console.log("[+] Pickup Successful", response.data);
        const contractResponse = await contract.methods
          .updateShipmentWithCollectorId(shipmentId, appState.userProfile.id)
          .send({ from: appState.account })
          .then((response) => {
            console.log("[+] Contract Response: ", response);
            toast.success("Pickup successful.");
            setLoading({
              loading: false,
              message: "Picking up Shipment...",
            });
            allShipmentsCollector(appState.userProfile.id, "0");
            return shipmentId;
          })
          .catch((error) => {
            console.log("[-] Contract Response: ", error);
            toast.error("Pickup Failed. Please try again.");
          });
      })
      .catch((error) => {
        console.log("[-] Pickup Failed", error);
        toast.error("Pickup Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Picking up Shipment...",
        });
      });
  };

  const pickupShipmentRecycler = async (shipmentId, recyclerData) => {
    console.log("<< Pickup Shipment Recycler Called  >>", shipmentId);
    setLoading({
      loading: true,
      message: "Picking up Shipment...",
    });
    let request = {
      id: shipmentId,
      status: "Collected by Recycler",
      recycler: { ...recyclerData },
    };

    console.log("[+] Request Object: ", request);

    const contract = new appState.web3.eth.Contract(
      abi,
      appState.contractAddress
    );

    await axios
      .post(`${appState.backendServer}/pickup-shipment-recycler`, request)
      .then(async (response) => {
        console.log("[+] Pickup Successful", response.data);
        const contractResponse = await contract.methods
          .updateShipmentWithRecyclerId(shipmentId, appState.userProfile.id)
          .send({ from: appState.account })
          .then((response) => {
            console.log("[+] Contract Response: ", response);
            toast.success("Pickup successful.");
            setLoading({
              loading: false,
              message: "Picking up Shipment...",
            });
            allShipmentsRecycler(appState.userProfile.id, "0");
            return shipmentId;
          })
          .catch((error) => {
            console.log("[-] Contract Response: ", error);
            toast.error("Pickup Failed. Please try again.");
          });
      })
      .catch((error) => {
        console.log("[-] Pickup Failed", error);
        toast.error("Pickup Failed. Please try again.");
        setLoading({
          loading: false,
          message: "Picking up Shipment...",
        });
      });
  };

  const sendForRecycle = async (batchArray, weight) => {
    if (batchArray.length === 0) {
      toast.error("Please select at least one batch to send for recycle.");
      return;
    } else {
      let tempBNumber = Math.floor(100000000000 + Math.random() * 900000000000);
      let objToSend = {};
      objToSend.batchArray = batchArray;
      objToSend.recyclerId = appState.userProfile.id;
      objToSend.recyclerName = appState.userProfile.name;
      objToSend.weight = weight;
      objToSend.batchNumber = tempBNumber;
      objToSend.timestamp = new Date()
        .toLocaleString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        .replace(",", " -");

      console.log("[+] Send for Recycle Object: ", objToSend);

      const contract = new appState.web3.eth.Contract(
        abi,
        appState.contractAddress
      );

      // uint256 _batchId,
      // uint256 _weight,
      // uint256 _recyclerId,
      // uint256 _timestamp,
      // address _addressOfTheCreator

      await axios
        .post(`${appState.backendServer}/sendForRecycle`, objToSend)
        .then(async (response) => {
          console.log("[+] Send for Recycle Successful", response.data);
          const contractResponse = await contract.methods
            .addBatch(
              tempBNumber,
              weight,
              appState.userProfile.id,
              new Date()
                .toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
                .replace(",", " -"),
              appState.account
            )
            .send({ from: appState.account })
            .then((response) => {
              console.log("[+] Contract Response: ", response);
              toast.success("Send for Recycle successful.");
              setLoading({
                loading: false,
                message: "Sending for Recycle...",
              });
              getAllRecycles();
            })
            .catch((error) => {
              console.log("[-] Contract Response: ", error);
              toast.error("Pickup Failed. Please try again.");
            });
        })
        .catch((error) => {
          console.log("[-] Send for Recycle Failed", error);
          toast.error("Send for Recycle Failed. Please try again.");
          setLoading({
            loading: false,
            message: "Sending for Recycle...",
          });
        });
    }
  };

  const getAllRecycles = async () => {
    await axios
      .get(`${appState.backendServer}/get-recycles`)
      .then((response) => {
        console.log("[+] Get All Recycles Successful", response.data);
        setLoading({
          loading: false,
          message: "Fetching Recycles...",
        });
        setAppState((prevState) => {
          return {
            ...prevState,
            recycles: response.data,
          };
        });
      })
      .catch((error) => {
        console.log("[-] Get All Recycles Failed", error);
        setLoading({
          loading: false,
          message: "Fetching Recycles...",
        });
      });
  };

  return (
    <>
      <Toaster />
      <div className="h-screen">
        {loading.loading === true ? (
          <Loading loading={loading} setLoading={setLoading} />
        ) : null}

        {/* <Header appState={appState} loggedInParty={loggedInParty} /> */}
        <Navbar appState={appState} walletLogout={walletLogout} />

        <Routes>
          <Route
            path="/"
            element={
              <Homepage
              // appState={appState}
              // setUpWeb3={setUpWeb3}
              // walletLogout={walletLogout}
              // regsisterUser={regsisterUser}
              // loginUser={loginUser}
              />
            }
          />
          <Route
            path="/signin"
            element={
              <Signin
                appState={appState}
                setUpWeb3={setUpWeb3}
                walletDisconnect={walletDisconnect}
                signin={signin}
              />
            }
          />
          <Route
            path="/signup"
            element={
              <Signup
                appState={appState}
                setUpWeb3={setUpWeb3}
                walletDisconnect={walletDisconnect}
                signup={signup}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                appState={appState}
                setUpWeb3={setUpWeb3}
                walletDisconnect={walletDisconnect}
                sendRequestToAggregator={sendRequestToAggregator}
                pickupShipment={pickupShipment}
                pickupShipmentCollector={pickupShipmentCollector}
                pickupShipmentRecycler={pickupShipmentRecycler}
                sendForRecycle={sendForRecycle}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
