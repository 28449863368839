import React, { useState, useEffect } from "react";

import DonorDashboard from "./donor/DonorDashboard";
import AggDashboard from "./aggregator/AggDashboard";
import CollectorDashboard from "./collector/CollectorDashboard";
import RecyclerDashboard from "./recycler/RecyclerDashboard";
import RFDashboard from "./reFacility/RFDashboard";

function Dashboard(props) {
  return (
    <>
      {props.appState.userProfile.role === "donor" ? (
        <DonorDashboard
          appState={props.appState}
          sendRequestToAggregator={props.sendRequestToAggregator}
        />
      ) : props.appState.userProfile.role === "aggregator" ? (
        <AggDashboard
          appState={props.appState}
          pickupShipment={props.pickupShipment}
        />
      ) : props.appState.userProfile.role === "collector" ? (
        <CollectorDashboard
          appState={props.appState}
          pickupShipmentCollector={props.pickupShipmentCollector}
        />
      ) : props.appState.userProfile.role === "recycler" ? (
        <RecyclerDashboard
          appState={props.appState}
          pickupShipmentRecycler={props.pickupShipmentRecycler}
          sendForRecycle={props.sendForRecycle}
        />
      ) : props.appState.userProfile.role === "reFacility" ? (
        <RFDashboard
          appState={props.appState}
          pickupShipment={props.pickupShipment}
        />
      ) : null}
    </>
  );
}

export default Dashboard;
