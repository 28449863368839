import React, { useState, useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import Barcode from "react-barcode";

import barcode from "../../assets/images/barcode.png";
import company from "../../assets/icons/company.svg";
import weight from "../../assets/icons/weight.svg";
import aggregator from "../../assets/icons/aggregator.svg";
import location from "../../assets/icons/location.svg";
import timestamp from "../../assets/icons/timestamp.svg";
import status from "../../assets/icons/status.svg";
import collector from "../../assets/icons/collector.svg";
import recycler from "../../assets/icons/recycler.svg";
import images from "../../assets/icons/images.svg";
import AggregatorDropdown from "../../components/AggregatorDropdown";

function RecyclerDashboard(props) {
  const [theme, setTheme] = useState("light");
  const [shipmentHistory, setShipmentHistory] = useState([]);
  const [pickupShipments, setPickupShipments] = useState([]);
  const [trackShipmentId, setTrackShipmentId] = useState("");
  const [collectionWeight, setCollectionWeight] = useState("");
  const [selectedWeight, setSelectedWeight] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [collectorData, setCollectorData] = useState({
    collectorId: props.appState.userProfile.id,
    collectorName: props.appState.userProfile.name,
    location: "",
    weight: "",
    timestamp: "",
    status: "Collected",
  });
  const [trackShipment, setTrackShipment] = useState({
    id: "",
    status: "",
    donor: {
      name: "",
      qty: "",
      timestamp: "",
      wasteType: "",
      location: "",
      aggregatorId: "",
      aggregatorName: "",
      id: "",
    },
  });
  const [modalShipment, setModalShipment] = useState({
    id: "",
    status: "",
    donor: {
      name: "",
      qty: "",
      timestamp: "",
      wasteType: "",
      location: "",
      aggregatorId: "",
      aggregatorName: "",
      id: "",
    },
  });

  let selectedRecycler = "";
  let selectedCollector = "";

  let locationName = "";

  const tagLocation = async () => {
    const apiKey = "AIzaSyChykMQlbWKcQy-qixkVnXCrGVoy-vdlM4";
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log("Latitude:", latitude);
          console.log("Longitude:", longitude);
          // You can perform further actions with the obtained latitude and longitude

          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
          try {
            const response = await axios.get(apiUrl);
            const data = response.data;

            if (data.results && data.results.length > 0) {
              const templocationName = data.results[0].formatted_address;
              console.log("Location Name:", templocationName);
              locationName = templocationName;
              toast.success("Location Tagged Successfully");
              console.log("Collector Data:", collectorData);
              // You can use the locationName for further processing or display it in your React component
            } else {
              console.log("Unable to retrieve location name.");
            }
          } catch (error) {
            console.error("Error retrieving location name:", error);
          }
        },
        function (error) {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported in this browser.");
    }
  };

  const processPickupShipments = () => {
    let tempPickups = [];
    setPickupShipments([]);
    if (props.appState.shipments.length > 0) {
      console.log("Processing Pickup Shipments:", props.appState.shipments);
      props.appState.shipments.forEach((shipment) => {
        if (
          shipment.status === "Roles Assigned" &&
          shipment.aggregator !== undefined &&
          shipment.aggregator.collectorId === props.appState.userProfile.id
        ) {
          let element = (
            <div className="flex flex-col p-4 px-8 bg-custom-three/30 rounded-3xl">
              <div className="text-sm text-white rounded-md w-fit bg-custom-three">
                <p className="p-2">#{shipment.id}</p>
              </div>
              <div className="grid grid-cols-2 grid-rows-3 mt-4 mb-4 gap-x-24 gap-y-4">
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={company} alt="company" className="w-6 h-6" />
                    <p className="text-sm font-bold">Company</p>
                  </div>
                  <p className="self-center text-sm font-normal">
                    {shipment.donor.name}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={weight} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Weight</p>
                  </div>
                  <p className="self-center text-sm">
                    {shipment.donor.qty} Kgs.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={aggregator} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Aggregator</p>
                  </div>
                  <div className="flex flex-col justify-center">
                    <p className="text-sm font-normal">
                      {shipment.donor.aggregatorName}
                    </p>
                    <p className="text-xs font-normal">
                      ID - {shipment.donor.aggregatorId}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-start space-x-4">
                    <img src={location} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Location</p>
                  </div>
                  <p className="self-center w-40 text-sm ">
                    {shipment.donor.location}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={timestamp} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Timestamp</p>
                  </div>
                  <p className="self-center text-sm font-normal ">
                    {shipment.donor.timestamp}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-x-16">
                  <div className="flex flex-row items-center space-x-4">
                    <img src={status} alt="weight" className="w-6 h-6" />
                    <p className="text-sm font-bold">Status</p>
                  </div>
                  <p className="self-center text-sm">{shipment.status}</p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between space-x-4">
                <div className="flex flex-row space-x-4">
                  {/* comp here */}
                  <input
                    type="text"
                    className="py-2 pl-6 rounded-full appearance-none bg-white/50 w-52 focus:outline-none "
                    placeholder="Enter Weight"
                    onChange={(e) => {
                      setCollectorData((prevState) => {
                        return {
                          ...prevState,
                          weight: e.target.value,
                        };
                      });
                    }}
                  />
                  <button className="py-2 pl-6 capitalize border-0 rounded-full appearance-none btn bg-custom-three w-52">
                    Upload Images
                  </button>
                  <button
                    className="py-2 pl-6 capitalize border-0 rounded-full appearance-none btn bg-custom-three w-52"
                    onClick={() => tagLocation()}
                  >
                    Tag Location
                  </button>
                </div>
                <button
                  className="px-10 py-1 text-white capitalize border-0 rounded-full btn bg-custom-three"
                  onClick={() => {
                    console.log("Accepting Pickup", collectorData);
                    if (collectorData.weight === "" || locationName === "") {
                      toast.error("Please provide weight and tag location");
                    } else {
                      let tempObj = collectorData;
                      tempObj.timestamp = new Date()
                        .toLocaleString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })
                        .replace(",", " -");
                      props.pickupShipmentCollector(shipment.id, tempObj);
                      console.log("Pickup Confirmed");
                    }
                  }}
                >
                  Confirm Pickup
                </button>
              </div>
            </div>
          );
          tempPickups.push(element);
        }
      });
      setPickupShipments(tempPickups);
    } else {
      console.log("No Shipments Found.");
    }
  };

  const processHistoryShipments = () => {
    let tempShipmentHistory = [];
    setShipmentHistory([]);
    if (props.appState.shipments.length > 0) {
      console.log("Processing Shipment History:", props.appState.shipments);

      // Sort shipments based on timestamp
      const sortedShipments = props.appState.shipments.sort((a, b) => {
        const timestampA = new Date(
          a.donor.timestamp.replace(",", "")
        ).getTime();
        const timestampB = new Date(
          b.donor.timestamp.replace(",", "")
        ).getTime();
        return timestampB - timestampA;
      });

      sortedShipments.forEach((shipment) => {
        if (shipment.collector) {
          let element = (
            <div className="flex flex-row items-center space-x-24 ">
              <p className="w-40">#{shipment.id}</p>
              <p className="w-48">{shipment.aggregator.timestamp}</p>
              <label
                htmlFor="my-modal"
                className={`"text-white capitalize bg-${theme} border-0 rounded-full btn btn-sm w-24 text-sm "`}
                onClick={() => {
                  console.log("View Shipment:", shipment.id);
                  setModalShipment(shipment);
                }}
              >
                View
              </label>
            </div>
          );
          tempShipmentHistory.push(element);
          setShipmentHistory(tempShipmentHistory);
        }
      });
    } else {
      console.log("No Shipments Found.");
    }
  };

  useEffect(() => {
    processHistoryShipments();
    processPickupShipments();
  }, [props.appState]);

  useEffect(() => {
    if (props.appState.userProfile.role === "donor") {
      setTheme("custom-one");
    } else if (props.appState.userProfile.role === "aggregator") {
      setTheme("custom-two");
    } else if (props.appState.userProfile.role === "aggregator-two") {
      setTheme("custom-three");
    } else if (props.appState.userProfile.role === "recycler-one") {
      setTheme("custom-four");
    } else if (props.appState.userProfile.role === "recycler-two") {
      setTheme("custom-five");
    }
  }, []);

  return (
    <>
      {/* Modal */}
      {/* ============================================== */}
      <input type="checkbox" id="my-modal" className="modal-toggle" />
      <div className="overflow-auto bg-white modal">
        <div className="flex flex-col items-center w-full mx-16 ">
          <div className=" w-fit rounded-2xl">
            <div className="flex flex-col w-fit">
              {/* Container - 1 */}
              <div className="flex flex-col p-4 px-8 bg-custom-one/30 rounded-3xl">
                <div className="text-sm text-white rounded-md w-fit bg-custom-one">
                  <p className="p-2">#{modalShipment.id}</p>
                </div>
                <div className="grid grid-cols-2 grid-rows-3 mt-4 mb-4 gap-x-24 gap-y-4">
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={company} alt="company" className="w-6 h-6" />
                      <p className="text-sm font-bold">Company</p>
                    </div>
                    <p className="self-center text-sm font-normal">
                      {modalShipment.donor.name}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={weight} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Weight</p>
                    </div>
                    <p className="self-center text-sm">
                      {modalShipment.donor.qty} Kgs.
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={aggregator} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Aggregator</p>
                    </div>
                    <div className="flex flex-col justify-center">
                      <p className="text-sm font-normal">
                        {modalShipment.donor.aggregatorName}
                      </p>
                      <p className="text-xs font-normal">
                        ID - {modalShipment.donor.aggregatorId}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-start space-x-4">
                      <img src={location} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Location</p>
                    </div>
                    <p className="self-center w-40 text-sm ">
                      {modalShipment.donor.location}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={timestamp} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Timestamp</p>
                    </div>
                    <p className="self-center text-sm font-normal ">
                      {modalShipment.donor.timestamp}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-x-16">
                    <div className="flex flex-row items-center space-x-4">
                      <img src={status} alt="weight" className="w-6 h-6" />
                      <p className="text-sm font-bold">Status</p>
                    </div>
                    <p className="self-center text-sm">
                      {modalShipment.status}
                    </p>
                  </div>
                </div>
              </div>
              {/* Container - 2 */}
              {modalShipment.aggregator ? (
                <div className="flex flex-col p-4 px-8 bg-custom-two/30 rounded-3xl">
                  <div className="grid grid-cols-2 grid-rows-2 mt-4 mb-4 gap-x-24 gap-y-4">
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={collector} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Collector</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">
                          {modalShipment.aggregator.collectorName}
                        </p>
                        <p className="text-xs font-normal">
                          ID - {modalShipment.aggregator.collectorId}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={recycler} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Recycler</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">
                          {modalShipment.aggregator.recyclerName}
                        </p>
                        <p className="text-xs font-normal">
                          ID - {modalShipment.aggregator.recyclerId}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Assigned Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.aggregator.timestamp}
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Assigned Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.aggregator.timestamp}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Container - 3 */}
              {modalShipment.collector ? (
                <div className="flex flex-col p-4 px-8 py-6 bg-custom-three/30 rounded-3xl">
                  <div className="text-sm text-black rounded-md w-fit ">
                    {/* <img src={barcode} alt="barcode" /> */}
                    <Barcode
                      value={modalShipment.id}
                      height={40}
                      displayValue={false}
                      margin={0}
                      background="#C4DCB8"
                    />
                  </div>
                  <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={collector} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Collector</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">
                          {modalShipment.collector.collectorName}
                        </p>
                        <p className="text-xs font-normal">
                          ID - {modalShipment.collector.collectorId}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={weight} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Weight</p>
                      </div>
                      <p className="self-center text-sm">
                        {modalShipment.collector.weight} Kgs.
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Pickup Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.collector.timestamp}
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={images} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Images</p>
                      </div>
                      <p className="self-center text-sm underline hover:cursor-pointer">
                        View
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={status} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Status</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        {modalShipment.collector.status}
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img
                          src={location}
                          alt="location"
                          className="w-6 h-6"
                        />
                        <p className="text-sm font-bold">Location</p>
                      </div>
                      <p className="self-center text-sm">
                        {modalShipment.collector.location}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Container - 4 */}
              {modalShipment.recycler ? (
                <div className="flex flex-col p-4 px-8 py-6 bg-custom-four/30 rounded-3xl">
                  <div className="text-sm text-black rounded-md w-fit ">
                    <Barcode
                      value={modalShipment.id}
                      height={40}
                      displayValue={false}
                      margin={0}
                      background="#C4DCB8"
                    />
                  </div>
                  <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={recycler} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Recycler</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-sm font-normal">Recycler XYZ</p>
                        <p className="text-xs font-normal">ID - 8586</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={weight} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Gross Weight</p>
                      </div>
                      <p className="self-center text-sm">750 Kgs.</p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={timestamp} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Recycle Date</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        18 Apr, 2023
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={images} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Images</p>
                      </div>
                      <p className="self-center text-sm underline hover:cursor-pointer">
                        View
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img src={status} alt="weight" className="w-6 h-6" />
                        <p className="text-sm font-bold">Status</p>
                      </div>
                      <p className="self-center text-sm font-normal">
                        Recycled
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-16">
                      <div className="flex flex-row items-center space-x-4">
                        <img
                          src={location}
                          alt="location"
                          className="w-6 h-6"
                        />
                        <p className="text-sm font-bold">Location</p>
                      </div>
                      <p className="self-center text-sm">City Center, Mumbai</p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col items-center w-full modal-action">
              <label
                htmlFor="my-modal"
                className="w-40 capitalize border-0 rounded-full btn bg-green"
              >
                Close
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* ============================================== */}
      {/* Modal End */}
      <div className="flex flex-col my-8 mb-8">
        {/* Container - 1 */}
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col mx-48">
            <p className="text-lg font-bold">Shipments available to Recycle</p>
            <p className="text-sm">
              List of available shipments to be sent to recycling facility
            </p>
            <button
              className="btn"
              onClick={() => {
                console.log(props.appState);
                // processHistoryShipments();
              }}
            >
              State
            </button>
          </div>
          <div className="flex flex-col space-y-2 mx-28">
            {pickupShipments.length > 0 ? (
              pickupShipments
            ) : (
              <p className="pt-4 ml-20 text-xl font-bold text-black/50">
                No Pickups Available
              </p>
            )}
          </div>
          <div className="flex flex-col space-y-4"></div>
        </div>
        <div className="mx-48 divider"></div>
        {/* Container - 1.1 */}
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col mx-48">
            <p className="text-lg font-bold">Send to Recycling Facility</p>
            <p className="text-sm">
              Provide desired weight to generate barcode
            </p>
          </div>
          <p className="mx-48 font-bold">
            Total Available waste: {totalWeight} Kgs
          </p>
          <p className="mx-48 font-bold">
            Rounded Weight: {selectedWeight} Kgs
          </p>
          <div className="flex flex-row mx-48 space-x-4">
            <input
              className="p-2 pl-5 border-2 border-black rounded-full w-60"
              placeholder="Weight in Kgs."
              type="text"
              value={collectionWeight}
              onChange={(e) => setCollectionWeight(e.target.value)}
            ></input>
            <button
              className={`"text-white capitalize bg-custom-four border-0 rounded-full w-72 btn text-sm py-0"`}
              onClick={async () => {
                if (totalWeight === 0) {
                  toast.error("No Shipments");
                } else {
                  toast.success("Shipments Bundled and Sent");
                }
              }}
            >
              Budle and Send Shipments
            </button>
          </div>
        </div>
        <div className="mx-48 divider"></div>
        {/* Container - 2 */}
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col mx-48">
            <p className="text-lg font-bold">Track Shipment Status</p>

            <p className="text-sm">Provide the Shipment ID to Track</p>
          </div>
          <div className="flex flex-row mx-48 space-x-4">
            <input
              className="p-2 pl-5 border-2 border-black rounded-full w-60"
              placeholder="Shipment ID"
              type="text"
              value={trackShipmentId}
              onChange={(e) => setTrackShipmentId(e.target.value)}
            ></input>
            <button
              className={`"text-white capitalize bg-custom-three border-0 rounded-full w-72 btn text-sm py-0"`}
              onClick={async () => {
                console.log("SHIPMENT ID", trackShipmentId);
                if (trackShipmentId !== undefined && trackShipmentId !== "") {
                  const shipment = props.appState.shipments.find(
                    (item) => item.id.toString() === trackShipmentId
                  );
                  if (shipment === undefined) {
                    console.log(shipment);
                    toast.error("Shipment not found");
                  } else {
                    setTrackShipment(shipment);
                  }
                } else {
                  toast.error("Please enter a valid shipment id");
                }
              }}
            >
              Track Shipment
            </button>
          </div>
          {/* Start of Shipment Card for Tracking */}
          {trackShipment !== undefined ? null : (
            <div className="flex flex-col items-center w-full pt-4">
              <div className=" w-fit rounded-2xl">
                <div className="flex flex-col w-fit">
                  {/* Container - 1 */}
                  <div className="flex flex-col p-4 px-8 bg-custom-one/30 rounded-3xl">
                    <div className="text-sm text-white rounded-md w-fit bg-custom-one">
                      <p className="p-2">#{trackShipment.id}</p>
                    </div>
                    <div className="grid grid-cols-2 grid-rows-3 mt-4 mb-4 gap-x-24 gap-y-4">
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img
                            src={company}
                            alt="company"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Company</p>
                        </div>
                        <p className="self-center text-sm font-normal">
                          {trackShipment.donor.name}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img src={weight} alt="weight" className="w-6 h-6" />
                          <p className="text-sm font-bold">Weight</p>
                        </div>
                        <p className="self-center text-sm">
                          {trackShipment.donor.qty} Kgs.
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img
                            src={aggregator}
                            alt="weight"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Aggregator</p>
                        </div>
                        <div className="flex flex-col justify-center">
                          <p className="text-sm font-normal">
                            {trackShipment.donor.aggregatorName}
                          </p>
                          <p className="text-xs font-normal">
                            ID - {trackShipment.donor.aggregatorId}
                          </p>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-start space-x-4">
                          <img
                            src={location}
                            alt="weight"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Location</p>
                        </div>
                        <p className="self-center w-40 text-sm ">
                          {trackShipment.donor.location}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img
                            src={timestamp}
                            alt="weight"
                            className="w-6 h-6"
                          />
                          <p className="text-sm font-bold">Timestamp</p>
                        </div>
                        <p className="self-center text-sm font-normal ">
                          {trackShipment.donor.timestamp}
                        </p>
                      </div>
                      <div className="grid grid-cols-2 gap-x-16">
                        <div className="flex flex-row items-center space-x-4">
                          <img src={status} alt="weight" className="w-6 h-6" />
                          <p className="text-sm font-bold">Status</p>
                        </div>
                        <p className="self-center text-sm">
                          {trackShipment.status}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Container - 2 */}
                  {trackShipment.aggregator ? (
                    <div className="flex flex-col p-4 px-8 bg-custom-three/30 rounded-3xl">
                      <div className="grid grid-cols-2 grid-rows-2 mt-4 mb-4 gap-x-24 gap-y-4">
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={collector}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Collector</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">Collector XYZ</p>
                            <p className="text-xs font-normal">ID - 8586</p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={recycler}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Recycler</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">Recycler XYZ</p>
                            <p className="text-xs font-normal">ID - 8586</p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Assigned Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            18 Apr, 2023
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Assigned Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            18 Apr, 2023
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* Container - 3 */}
                  {trackShipment.collector ? (
                    <div className="flex flex-col p-4 px-8 py-6 bg-custom-three/30 rounded-3xl">
                      <div className="text-sm text-black rounded-md w-fit ">
                        <img src={barcode} alt="barcode" />
                      </div>
                      <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={collector}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Collector</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">Collector XYZ</p>
                            <p className="text-xs font-normal">ID - 8586</p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={weight}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Weight</p>
                          </div>
                          <p className="self-center text-sm">750 Kgs.</p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Pickup Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            18 Apr, 2023
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={images}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Images</p>
                          </div>
                          <p className="self-center text-sm underline hover:cursor-pointer">
                            View
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={status}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Status</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            Collected
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={location}
                              alt="location"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Location</p>
                          </div>
                          <p className="self-center text-sm">
                            City Center, Mumbai
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* Container - 4 */}
                  {trackShipment.recycler ? (
                    <div className="flex flex-col p-4 px-8 py-6 bg-custom-four/30 rounded-3xl">
                      <div className="text-sm text-black rounded-md w-fit ">
                        <img src={barcode} alt="barcode" />
                      </div>
                      <div className="grid grid-cols-2 grid-rows-3 mt-6 mb-4 gap-x-24 gap-y-4">
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={recycler}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Recycler</p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-sm font-normal">Recycler XYZ</p>
                            <p className="text-xs font-normal">ID - 8586</p>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={weight}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Gross Weight</p>
                          </div>
                          <p className="self-center text-sm">750 Kgs.</p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={timestamp}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Recycle Date</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            18 Apr, 2023
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={images}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Images</p>
                          </div>
                          <p className="self-center text-sm underline hover:cursor-pointer">
                            View
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={status}
                              alt="weight"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Status</p>
                          </div>
                          <p className="self-center text-sm font-normal">
                            Recycled
                          </p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-16">
                          <div className="flex flex-row items-center space-x-4">
                            <img
                              src={location}
                              alt="location"
                              className="w-6 h-6"
                            />
                            <p className="text-sm font-bold">Location</p>
                          </div>
                          <p className="self-center text-sm">
                            City Center, Mumbai
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {/* End of Shipment Card for Tracking */}
        </div>
        <div className="mx-48 divider"></div>
        {/* Container - 3 */}
        <div className="flex flex-col mx-48 mb-16 space-y-4">
          <div className="flex flex-col">
            <p className="text-lg font-bold">Shipment History</p>
            <p className="text-sm">History of previous shipments</p>
          </div>
          {shipmentHistory.length === 0 ? (
            <div>
              <p className="text-xl font-bold text-black/50">No Shipments</p>
            </div>
          ) : (
            <div className="flex flex-col space-y-2">{shipmentHistory}</div>
          )}
        </div>
      </div>
    </>
  );
}

export default RecyclerDashboard;
